import React, { useState } from "react";
import { requestPasswordReset } from "../../lib/auth"; // Import de la fonction d'API
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import AuthLayout from "./AuthLayout";

const ResetPasswordRequest: React.FC = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    setSuccessMessage("");

    try {
      await requestPasswordReset(email);
      setSuccessMessage(
        "Un email de réinitialisation a été envoyé. Veuillez vérifier votre boîte de réception.",
      );
    } catch (err: any) {
      console.error("Password reset request failed:", err);
      setError(
        "La demande de réinitialisation de mot de passe a échoué. Veuillez réessayer.",
      );
    }
  };

  return (
    <AuthLayout authAction="Mot de passe oublié">
      <form onSubmit={handleSubmit}>
        <div className="mb-4 flex flex-col gap-4">
          {error && <p className="text-sm text-red-500">{error}</p>}
          {successMessage && (
            <p className="text-sm text-green-500">{successMessage}</p>
          )}
          <div className="relative">
            <Input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <Label design="auth" htmlFor="email">
              Email
            </Label>
          </div>
        </div>

        <Button type="submit" hover="jumping" size="full">
          Envoyer
        </Button>
      </form>
    </AuthLayout>
  );
};

export default ResetPasswordRequest;
