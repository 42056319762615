import { Bell, Search } from "lucide-react";
import { cn } from "../lib/utils";
import { Language } from "../types";
import { Input } from "./ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";

type HeaderProps = {
  title: string;
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  languages: Language[];
  isDashboard?: boolean;
  isChat?: boolean;
};

export default function Header({
  title,
  searchQuery,
  setSearchQuery,
  languages,
  isDashboard,
  isChat,
}: HeaderProps) {
  return (
    <div className="mx-12 flex items-center justify-between">
      <h1
        className={cn(
          isDashboard ? "text-white" : "text-blue",
          "w-28 text-2xl font-semibold",
        )}
      >
        {title}
      </h1>
      <div className={cn("flex w-80 items-center rounded-xl bg-gray-100 px-4")}>
        <Search className="text-blue" />
        <Input
          placeholder="Rechercher..."
          className="w-full rounded-full border-none text-dark focus:outline-none focus:ring-0 focus-visible:ring-0"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      <div className="flex items-center gap-20">
        {/* <Select>
          <SelectTrigger className="w-fit capitalize text-dark">
            <SelectValue placeholder="Langue" />
          </SelectTrigger>
          <SelectContent>
            {languages.map((language) => (
              <SelectItem
                key={language.language_id}
                value={language.language_id}
              >
                {language.language_name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        <button className="rounded-lg bg-orange-light p-2">
          <Bell className="size-6 text-orange" />
        </button> */}
      </div>
    </div>
  );
}
