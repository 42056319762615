import { Scraping } from "../types";
import axiosInstance from "./axiosInstance";

export const createNewScraping = async (
  focus_name: string,
  keyword_logic: string[][],
  social_media_ids: string[],
  start_date: string,
): Promise<Scraping> => {
  try {
    const response = await axiosInstance.post("/scraping", {
      focus_name,
      keyword_logic,
      social_media_ids,
      start_date, // should be in ISO 8601 format e.g. "2024-10-08T10:20:30Z"
    });

    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        error.response.data.detail || "Failed to create scraping",
      );
    } else {
      throw new Error("Failed to create scraping");
    }
  }
};

export const getScrapingsByFocus = async (
  focus_id: string,
): Promise<Scraping[]> => {
  try {
    const response = await axiosInstance.get<Scraping[]>(
      `/scraping/${focus_id}`,
    );
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(error.response.data.detail || "Failed to fetch focus");
    } else {
      throw new Error("Failed to fetch focus");
    }
  }
};
