import { LoaderCircle } from "lucide-react";
import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useUser } from "../context/UserContext";

const PrivateRoute: React.FC = () => {
  const { user, loading } = useUser();

  useEffect(() => {}, [user, loading]);

  if (loading) {
    return (
      <div className="size-full p-48">
        <LoaderCircle className="mx-auto size-32 animate-spin text-blue" />
      </div>
    ); // Show loading indicator
  }

  if (!user) {
    return <Navigate to="/auth/login" replace />;
  }

  return <Outlet />;
};

export default PrivateRoute;
