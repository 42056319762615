import { ResponsiveBar } from "@nivo/bar";
import React from "react";
import { FocusWithSentimentScore } from "../../types";

interface BarChartProps {
  data: FocusWithSentimentScore[];
}

const BarChart: React.FC<BarChartProps> = ({ data }) => {
  const chartData = data.map((focus) => ({
    focus: focus.name,
    sentimentScore: +focus.sentiment_score.toFixed(2),
  }));

  const chartWidth = chartData.length * 70;

  const getBarColor = (bar: { data: { sentimentScore: number } }) => {
    const score = bar.data.sentimentScore;
    if (score < -0.6) return "#F6C443";
    if (score > -0.6 && score < -0.3) return "#FAD58B";
    if (score > -0.3 && score < 0) return "#FDF3D9";
    if (score >= 0 && score < 0.3) return "#DDF9E1";
    if (score >= 0.3 && score < 0.6) return "#A4E5B3";
    return "#53E16A";
  };

  return (
    <div style={{ height: "350px", width: `${chartWidth}px` }}>
      <ResponsiveBar
        data={chartData}
        keys={["sentimentScore"]}
        indexBy="focus"
        margin={{ top: 0, right: 0, bottom: 40, left: 0 }} // Increased bottom margin for label space
        layout="vertical"
        padding={0.4}
        minValue={-1}
        maxValue={1}
        colors={getBarColor} // Use the function to define conditional colors
        borderRadius={6} // Rounded bars
        borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0,
          legend: "",
          legendPosition: "middle",
          legendOffset: 32,
          format: () => "", // Disable the default labels
        }}
        axisLeft={null}
        enableGridY={false}
        enableLabel={false}
        labelSkipWidth={8}
        labelSkipHeight={8}
        labelTextColor="#000"
        animate={true}
        tooltip={({ indexValue, value, color }) => (
          <div
            style={{
              padding: "5px 10px",
              color: "#151D48",
              background: "white",
              border: `1px solid ${color}`,
              borderRadius: "5px",
            }}
          >
            <strong>{indexValue}</strong> {/* Product name */}
            <br />
            Score: {value} {/* Display the score */}
          </div>
        )}
        theme={{
          axis: {
            ticks: {
              text: {
                fontSize: 10, // Smaller text size for tick labels
                fill: "#7F8C8D", // Axis tick color
              },
            },
          },
        }}
        layers={[
          "grid", // Default layers (grid, axes, bars)
          "axes",
          "bars",
          // Custom layer to manually handle multi-line labels
          ({ bars, yScale }) => (
            <g>
              {bars.map((bar) => (
                <text
                  key={bar.data.data.focus}
                  x={bar.x + bar.width / 2}
                  y={yScale(-1) + 15} // Move labels below even more
                  textAnchor="middle"
                  style={{ fill: "#7F8C8D", fontSize: "10px" }}
                >
                  {bar.data.data.focus.split(" ").map((word, i) => (
                    <tspan
                      x={bar.x + bar.width / 2}
                      dy={i === 0 ? 0 : 12}
                      key={i}
                    >
                      {word}
                    </tspan>
                  ))}
                </text>
              ))}
            </g>
          ),
          // Custom layer to draw the line at y = 0 with padding
          ({ bars, yScale }) => {
            const firstBarX = bars[0].x;
            const lastBarX =
              bars[bars.length - 1].x + bars[bars.length - 1].width;

            return (
              <line
                x1={firstBarX - 10} // Start 10px before the first bar
                x2={lastBarX + 10} // End 10px after the last bar
                y1={yScale(0)} // Position the line at y = 0
                y2={yScale(0)}
                stroke="#d3d3d3" // Lighter line color (gray)
                strokeWidth={1} // Thinner line
              />
            );
          },
        ]}
      />
    </div>
  );
};

export default BarChart;
