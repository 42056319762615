import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { resetPassword } from "../../lib/auth"; // Import the reset password function
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import AuthLayout from "./AuthLayout";

const PasswordReset: React.FC = () => {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  // Extract the token from the URL query parameter
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  useEffect(() => {
    if (!token) {
      setError(
        "Token de réinitialisation manquant. Veuillez vérifier votre email.",
      );
    }
  }, [token]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    setSuccessMessage("");

    if (!token) {
      setError("Token de réinitialisation manquant.");
      return;
    }

    if (password !== passwordConfirm) {
      setError("Les mots de passe ne correspondent pas.");
      return;
    }

    try {
      // Call the resetPassword function from auth.ts
      await resetPassword(token, password);
      setSuccessMessage("Votre mot de passe a été réinitialisé avec succès.");

      // Optionally redirect after a short delay
      setTimeout(() => navigate("/auth/login"), 3000);
    } catch (err: any) {
      setError(
        err.message ||
          "Échec de la réinitialisation du mot de passe. Veuillez réessayer.",
      );
    }
  };

  return (
    <AuthLayout authAction="Réinitialisation du mot de passe">
      <form onSubmit={handleSubmit}>
        <div className="mb-4 flex flex-col gap-4">
          {error && <p className="text-sm text-red-500">{error}</p>}
          {successMessage && (
            <p className="text-sm text-green-500">{successMessage}</p>
          )}
          <div className="relative">
            <Input
              type="password"
              id="password"
              className="font-medium"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <Label design="auth" htmlFor="password">
              Mot de passe
            </Label>
          </div>

          <div className="relative">
            <Input
              type="password"
              id="passwordConfirm"
              className="font-medium"
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
              required
            />
            <Label design="auth" htmlFor="passwordConfirm">
              Confirmation du mot de passe
            </Label>
          </div>
        </div>

        <Button type="submit" hover="jumping" size="full">
          Réinitialiser
        </Button>
      </form>
    </AuthLayout>
  );
};

export default PasswordReset;
