import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const GoogleCallbackPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const accessToken = urlParams.get("access_token");
    const refreshToken = urlParams.get("refresh_token");

    if (accessToken && refreshToken) {
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);

      window.location.href = "/"; // Redirect to the home page
    } else {
      console.error("Tokens missing in the callback URL");
      navigate("/auth/login");
    }
  }, [navigate]);

  return <div>Connexion en cours...</div>;
};

export default GoogleCallbackPage;
