import { ResponsiveLine } from "@nivo/line";
import { format } from "date-fns";
import React from "react";

interface LineChartProps {
  data: {
    id: string;
    data: {
      x: Date;
      y: number;
    }[];
  }[];
}

const LineChart: React.FC<LineChartProps> = ({ data }) => {
  // Vérifier si les données sont vides
  const isEmpty = data.every((d) => d.data.length === 0);

  return (
    <div style={{ height: 250 }}>
      {isEmpty ? (
        <div className="flex h-full items-center justify-center">
          <p className="text-gray-500">Aucunes données disponibles.</p>
        </div>
      ) : (
        <ResponsiveLine
          data={data}
          margin={{ top: 20, right: 40, bottom: 60, left: 80 }}
          xScale={{ type: "time", format: "%Y-%m-%d", precision: "day" }}
          yScale={{
            type: "linear",
            min: -1,
            max: 1,
            stacked: false,
            reverse: false,
          }}
          axisTop={null}
          axisRight={null}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            tickValues: [-1, -0.5, 0, 0.5, 1],
            format: (value) => {
              if (value === -1) return "Très Négatif";
              if (value === -0.5) return "Négatif";
              if (value === 0) return "Neutre";
              if (value === 0.5) return "Positif";
              if (value === 1) return "Très Positif";
              return ""; // Cacher les autres ticks
            },
            legendOffset: -40,
            legendPosition: "middle",
          }}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 45, // Rotation pour éviter l'encombrement
            format: (value) => format(new Date(value), "dd/MM/yyyy"), // Formater en "jour/mois/année"
          }}
          enableArea={true}
          areaBaselineValue={-1}
          areaOpacity={0.3}
          curve="monotoneX"
          pointSize={0}
          isInteractive={false}
          enablePoints={false}
          enableGridX={false} // Désactiver les lignes verticales
          enableGridY={true} // Garder les lignes horizontales
          gridYValues={[-1, -0.5, 0, 0.5, 1]} // Spécifie explicitement les ticks Y
          useMesh={true}
          colors={["#53E16A"]} // Couleur personnalisée pour la ligne
          lineWidth={2}
          defs={[
            {
              id: "customSentimentGradient",
              type: "linearGradient",
              colors: [
                { offset: 0, color: "#53E16A" }, // Vert pour sentiment positif
                { offset: 100, color: "#DDF9E1" }, // Jaune pour sentiment neutre
              ],
            },
          ]}
          fill={[
            {
              match: "*", // Appliquer le remplissage à tout le graphique
              id: "customSentimentGradient",
            },
          ]}
          animate={true} // Activer l'animation
          motionConfig="gentle" // Utiliser la configuration d'animation prédéfinie
        />
      )}
    </div>
  );
};

export default LineChart;
