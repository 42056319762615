import {
  FocusWithSentimentScore,
  RecentGoogleNewsData,
  SentimentGraphData,
  SentimentPieData,
} from "../types";
import axiosInstance from "./axiosInstance";

type GetSentimentGraphData = {
  focus_id: string;
  start_date: string;
  end_date: string;
  social_media_ids?: string[];
};

export const getSentimentGraph = async ({
  focus_id,
  start_date,
  end_date,
  social_media_ids,
}: GetSentimentGraphData): Promise<SentimentGraphData> => {
  try {
    const response = await axiosInstance.post(
      "/focus_analysis/sentiment_graph",
      {
        focus_id,
        start_date,
        end_date,
        social_media_ids,
      },
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw new Error("Error fetching graph data");
    }
  }
};

export const generateSummary = async (
  focus_id: string,
  social_media_id?: string,
): Promise<string> => {
  try {
    const response = await axiosInstance.post("/focus/generate_summary", {
      focus_id,
      social_media_id: social_media_id || null, // Send null if undefined
    });
    return response.data; // Retourner le résumé généré (string)
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        error.response.data.detail || "Failed to generate summary",
      );
    } else {
      throw new Error("Failed to generate summary");
    }
  }
};

export const generateSentiment = async (
  focus_id: string,
  social_media_id?: string,
): Promise<SentimentPieData> => {
  try {
    const response = await axiosInstance.post("/focus/generate_ratio", {
      focus_id, // Add focus_id in the body
      social_media_id: social_media_id || null, // Add social_media_id in the body, defaulting to null if undefined
    });

    return response.data; // Return the object (dictionary) representing the sentiment analysis
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        error.response.data.detail || "Failed to generate sentiment analysis",
      );
    } else {
      throw new Error("Failed to generate sentiment analysis");
    }
  }
};

export const getMostRecentGoogleNews = async (
  focus_id: string,
): Promise<RecentGoogleNewsData[]> => {
  try {
    const response = await axiosInstance.get(
      `/focus_analysis/most_recent_gg_news/${focus_id}`,
    );

    const responseFormatted = response.data.map((news: any) => {
      return {
        author: news.author_affiliation || news.username,
        content: news.content,
        url: news.url,
        date: news.date,
      };
    });

    return responseFormatted;
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        error.response.data.detail || "Failed to fetch Google News",
      );
    } else {
      throw new Error("Failed to fetch Google News");
    }
  }
};

export const getListAppreciatedFocuses = async (): Promise<
  FocusWithSentimentScore[]
> => {
  try {
    const response = await axiosInstance.get(
      "/focus_analysis/list_appreciated_focuses",
    );
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        error.response.data.detail ||
          "Failed to fetch most appreciated focuses",
      );
    } else {
      throw new Error("Failed to fetch most appreciated focuses");
    }
  }
};

export const getLeastAppreciatedFocuses = async (): Promise<any> => {
  try {
    const response = await axiosInstance.get(
      "/focus_analysis/least_appreciated_focuses",
    );
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        error.response.data.detail ||
          "Failed to fetch least appreciated focuses",
      );
    } else {
      throw new Error("Failed to fetch least appreciated focuses");
    }
  }
};
