import { ResponsivePie } from "@nivo/pie";
import React from "react";
import { SentimentPieData } from "../../types";

interface SentimentPieChartProps {
  sentiment: SentimentPieData; // Pass sentiment data as a prop
}

const SentimentPieChart: React.FC<SentimentPieChartProps> = ({ sentiment }) => {
  // Prepare the data for the pie chart
  const sentimentData = [
    {
      id: "Positif",
      label: "Positif",
      value: (sentiment.positive_ratio * 100).toFixed(2), // Positive ratio from API
      color: "#53E16A", // Custom color
    },
    {
      id: "Negatif",
      label: "Negatif",
      value: (sentiment.negative_ratio * 100).toFixed(2), // Negative ratio from API
      color: "#9F58E1",
    },
    {
      id: "Neutre",
      label: "Neutre",
      value: (sentiment.neutral_ratio * 100).toFixed(2), // Neutral ratio from API
      color: "#F6C443",
    },
  ];

  return (
    <div style={{ height: 230 }}>
      <ResponsivePie
        data={sentimentData}
        margin={{ top: 20, right: 50, bottom: 20, left: 50 }}
        innerRadius={0.5}
        padAngle={1}
        activeOuterRadiusOffset={12}
        cornerRadius={3}
        colors={{ datum: "data.color" }}
        borderWidth={1}
        borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
        arcLabel={(arc) => `${arc.value}%`}
        arcLabelsTextColor={"#ECF6FF"}
        arcLinkLabel={(arc) => `${arc.label}`}
        arcLinkLabelsTextOffset={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsDiagonalLength={12}
        arcLinkLabelsStraightLength={24}
        arcLinkLabelsThickness={11}
        arcLinkLabelsColor={{ from: "color" }}
      />
    </div>
  );
};

export default SentimentPieChart;
