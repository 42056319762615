import {
  addWeeks,
  format,
  isAfter,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subYears,
} from "date-fns";
import { LoaderCircle, SquareChartGantt } from "lucide-react";
import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { useUser } from "../context/UserContext";
import {
  generateSentiment,
  generateSummary,
  getMostRecentGoogleNews,
  getSentimentGraph,
} from "../lib/analysis";
import { getFocusByID, getNumberOfMentions } from "../lib/focus";
import { getScrapingsByFocus } from "../lib/scraping";
import { cn, getSocialMediaName } from "../lib/utils";
import { Focus, Scraping, SentimentGraphData } from "../types";
import LineChart from "./charts/line-chart";
import SentimentPieChart from "./charts/pie-chart";
import { Dialog, DialogContent, DialogTrigger } from "./ui/dialog";
import LoaderDots from "./ui/loader-dots";
import { Separator } from "./ui/separator";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";

const FocusDetail: React.FC = () => {
  const { socialMedias } = useUser();
  const { id } = useParams<{ id: string }>(); // Get the ID from the URL
  const focusId = id;
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Date range filter
  const [selectedRange, setSelectedRange] = useState<
    "7jours" | "30jours" | "3mois" | "6mois" | "1an"
  >("1an"); // Default to 1 year

  // Social Media IDs
  const socialMediaIds = socialMedias.map((social) => social.social_media_id);

  const {
    data: focus,
    isLoading: focusLoading,
    error: focusError,
  } = useQuery<Focus>(["focus", focusId], () => getFocusByID(focusId!), {
    staleTime: 60000,
  });

  // Fetch data for 1 year initially
  const {
    data: sentimentLineData,
    isLoading: graphLoading,
    error: graphError,
  } = useQuery<SentimentGraphData>(
    ["graphData", focusId, socialMediaIds],
    () =>
      getSentimentGraph({
        focus_id: focusId!,
        start_date: subYears(new Date(), 1).toISOString().split("T")[0],
        end_date: new Date().toISOString().split("T")[0],
      }),
    { staleTime: 60000 },
  );

  // Fetch other data
  const {
    data: summary,
    isLoading: summaryLoading,
    error: summaryError,
  } = useQuery<string>(["summary", focusId], () => generateSummary(focusId!), {
    staleTime: 60000,
  });

  const {
    data: sentimentPieData,
    isLoading: sentimentLoading,
    error: sentimentError,
  } = useQuery(["sentiment", focusId], () => generateSentiment(focusId!), {
    staleTime: 60000,
  });

  const {
    data: googleNews,
    isLoading: googleNewsLoading,
    error: googleNewsError,
  } = useQuery(
    ["googleNews", focusId],
    () => getMostRecentGoogleNews(focusId!),
    {
      staleTime: 60000,
    },
  );
  const {
    data: scrapings,
    isLoading: scrapingsLoading,
    error: scrapingsError,
  } = useQuery<Scraping[]>(
    ["scrapings", focusId],
    () => getScrapingsByFocus(focusId!),
    { staleTime: 60000 },
  );

  const {
    data: numberOfMentions,
    isLoading: numberOfMentionsLoading,
    error: numberOfMentionsError,
  } = useQuery<number>(
    ["numberOfMentions"],
    () => getNumberOfMentions([focusId as string]),
    {
      enabled: !!focusId, // Query only runs if `focusId` is defined
      staleTime: 60000,
    },
  );
  // Function to group by date (day, week, or month)
  const groupByDate = (date: Date, groupByType: string): string => {
    switch (groupByType) {
      case "day":
        return format(date, "yyyy-MM-dd");
      case "week":
        const startWeek = startOfWeek(date, { weekStartsOn: 1 });
        return format(startWeek, "yyyy-'W'ww");
      case "month":
      default:
        return format(date, "yyyy-MM");
    }
  };

  // Get groupBy type based on selected range
  const getGroupByType = (range: string): string => {
    if (["7jours", "30jours"].includes(range)) return "day";
    if (["3mois", "6mois"].includes(range)) return "week";
    return "month"; // Par défaut, regroupe par mois
  };

  // Filter data based on selected range
  const filteredData = useMemo(() => {
    if (!sentimentLineData || !sentimentLineData.sentiment_data) return [];

    const groupByType = getGroupByType(selectedRange);
    const today = new Date();
    let rangeStartDate: Date | null = null;

    switch (selectedRange) {
      case "7jours":
        rangeStartDate = subDays(today, 7);
        break;
      case "30jours":
        rangeStartDate = subDays(today, 30);
        break;
      case "3mois":
        rangeStartDate = subMonths(today, 3);
        break;
      case "6mois":
        rangeStartDate = subMonths(today, 6);
        break;
      case "1an":
      default:
        rangeStartDate = subYears(today, 1);
    }

    const grouped = sentimentLineData.sentiment_data.reduce(
      (
        acc: { [key: string]: { scoreSum: number; count: number } },
        current,
      ) => {
        const date = new Date(current.date);
        const key = groupByDate(date, groupByType);

        if (!acc[key]) {
          acc[key] = { scoreSum: 0, count: 0 };
        }

        acc[key].scoreSum += current.sentiment_score;
        acc[key].count += 1;

        return acc;
      },
      {},
    );

    const getDateFromKey = (key: string): Date => {
      if (key.includes("W")) {
        const [year, week] = key.split("-W");
        const firstDayOfYear = startOfYear(new Date(parseInt(year, 10), 0, 1));
        const dateForWeek = addWeeks(firstDayOfYear, parseInt(week, 10) - 1);
        return dateForWeek; // Retourner la date correspondant à cette semaine
      } else if (key.length === 7) {
        return new Date(key + "-01");
      } else {
        return new Date(key);
      }
    };

    const filteredData = Object.entries(grouped)
      .filter(([date]) => {
        const dateObj = getDateFromKey(date);
        return !rangeStartDate || isAfter(dateObj, rangeStartDate);
      })
      .map(([date, value]) => ({
        x: getDateFromKey(date),
        y: value.scoreSum / value.count,
      }));

    return filteredData;
  }, [sentimentLineData, selectedRange]);

  const allSocialMediasForScrapings = scrapings?.reduce((acc, scraping) => {
    scraping.social_media_ids?.forEach((social) => {
      if (!acc.includes(social)) {
        acc.push(social);
      }
    });
    return acc;
  }, [] as string[]);

  return (
    <div className="space-y-8 p-8">
      {/* Focus Name */}
      {focusLoading ? (
        <LoaderDots />
      ) : focusError ? (
        <p className="text-red-500">Erreur lors du chargement du focus.</p>
      ) : (
        focus && (
          <div className="mx-12 flex items-center justify-between">
            <div className="flex items-center gap-6 text-2xl font-semibold text-dark">
              <h1 className="flex h-12 items-center">{focus.name}</h1>

              <Separator orientation="vertical" className="h-10" />

              {allSocialMediasForScrapings && (
                <div className="flex items-center gap-4">
                  <div className="flex h-12 items-center gap-2">
                    {allSocialMediasForScrapings.map((social) => {
                      /* const Icon = getSocialMediaIcon(social, socialMedias); */
                      const name = getSocialMediaName(social, socialMedias);
                      return (
                        <>
                          <span>{name}</span>
                        </>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>

            <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
              <DialogTrigger asChild>
                <div className="rounded-lg bg-purple-light px-4 py-2 text-sm hover:cursor-pointer">
                  Afficher l'historique des paramétrages
                </div>
              </DialogTrigger>
              <DialogContent className="max-h-[calc(100vh-50px)] max-w-5xl overflow-y-scroll">
                <h2 className="text-center text-lg font-bold">
                  Historique des paramétrages
                </h2>
                {scrapingsLoading ? (
                  <LoaderDots />
                ) : scrapingsError ? (
                  <p className="text-red-500">
                    Erreur lors du chargement des données de scraping.
                  </p>
                ) : (
                  <div className="mt-2 overflow-hidden rounded-3xl">
                    <Table className="w-full table-auto rounded-2xl">
                      <TableHeader className="bg-purple text-white">
                        <TableRow>
                          <TableHead className="px-4 py-3">
                            Date de création
                          </TableHead>
                          <TableHead className="px-4 py-3">Statut</TableHead>
                          <TableHead className="px-4 py-3">Mots clés</TableHead>
                          <TableHead className="px-4 py-3">
                            Réseaux sociaux
                          </TableHead>
                          <TableHead className="px-4 py-3">
                            Historique du scraping
                          </TableHead>
                        </TableRow>
                      </TableHeader>
                      {scrapings && (
                        <TableBody>
                          {scrapings.map((scraping, index) => (
                            <TableRow
                              key={index}
                              className={
                                index % 2 === 0 ? "bg-purple-light" : ""
                              }
                            >
                              <TableCell className="px-4 py-2">
                                {new Date(
                                  scraping.created_at,
                                ).toLocaleDateString("fr-FR", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                })}
                              </TableCell>
                              <TableCell className="px-4 py-2">
                                <p
                                  className={cn(
                                    scraping.status === "In Progress" &&
                                      "bg-orange",
                                    scraping.status === "Completed" &&
                                      "bg-green",
                                    scraping.status === "Failed" &&
                                      "bg-red-500",
                                    "w-fit rounded-full px-5 py-0.5",
                                  )}
                                >
                                  {scraping.status}
                                </p>
                              </TableCell>
                              <TableCell className="flex flex-col gap-1 px-4 py-2">
                                {scraping.keyword_logic.map((keyword) => (
                                  <p>{keyword.join(" - ")}</p>
                                ))}
                              </TableCell>
                              <TableCell className="px-4 py-2">
                                {scraping.social_media_ids?.map((social) => (
                                  <p>
                                    {getSocialMediaName(social, socialMedias)}
                                  </p>
                                ))}
                              </TableCell>
                              <TableCell className="px-4 py-2">
                                depuis le{" "}
                                {new Date(
                                  scraping.start_date,
                                ).toLocaleDateString("fr-FR", {
                                  year: "numeric",
                                  month: "long",
                                  day: "2-digit",
                                })}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      )}
                    </Table>
                  </div>
                )}
              </DialogContent>
            </Dialog>
          </div>
        )
      )}

      {/* Summary Section */}
      <div className="grid grid-cols-5 gap-3">
        <div className="col-span-3 rounded-3xl bg-blue-light p-4 text-blue shadow-lg shadow-gray-300/70">
          <div className="flex gap-4">
            <img
              src="/moodee-v1.png"
              alt="logo"
              className="size-6 rounded-full"
            />
            <div className="flex w-full flex-col">
              <h2 className="mb-2 text-lg font-bold">Moodee - Résumé</h2>
              {summaryLoading ? (
                <div className="mx-auto mt-4 w-full">
                  <LoaderDots />
                </div>
              ) : summaryError ? (
                <p className="text-red-500">
                  Erreur lors du chargement du résumé.
                </p>
              ) : (
                <p className="text-justify text-base leading-5">{summary}</p>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-between gap-3 rounded-3xl bg-orange-light px-6 py-4">
          <div className="size-fit rounded-full bg-orange p-3">
            <SquareChartGantt size={24} className="text-white" />
          </div>
          <div>
            {numberOfMentionsLoading ? (
              <p>Chargement...</p>
            ) : numberOfMentionsError ? (
              <p>Erreur</p>
            ) : (
              <p className="text-xl font-bold">{numberOfMentions}</p>
            )}
            <p className="text-sm font-normal">Total des mentions</p>
          </div>
        </div>

        <div className="flex flex-col justify-between gap-4 rounded-3xl bg-blue p-4 text-white shadow-lg shadow-gray-300/70">
          <div>
            <h3 className="text-lg font-semibold">Chatbot Moodee</h3>
            <p className="mt-1 text-sm font-light">
              Pose des questions à Moodee sur ton produit et obtiens des
              analyses précises pour une stratégie affinée.
            </p>
          </div>
          <Link
            to="/chat"
            className="mx-auto w-fit rounded-lg bg-white px-6 py-2 font-semibold text-blue"
          >
            PARLER AU CHAT
          </Link>
        </div>
      </div>

      {/* Sentiment Line Chart */}
      <div className="grid grid-cols-3 gap-2">
        <div className="col-span-2 flex flex-col gap-6">
          <div className="rounded-3xl border border-gray-100 bg-white p-4 shadow-lg shadow-gray-300/70">
            <h2 className="text-center text-lg font-bold">
              Évolution du sentiment client
            </h2>
            <div className="mx-10 mb-2 mt-4 flex items-center justify-between gap-2 border-b border-green pb-1">
              <p className="text-sm">Choisis la période à analyser</p>
              <div className="flex gap-2">
                {["7jours", "30jours", "3mois", "6mois", "1an"].map((range) => (
                  <button
                    key={range}
                    className={cn(
                      selectedRange === range
                        ? "border-green bg-green text-white shadow-md"
                        : "border border-green text-green hover:bg-green hover:text-white",
                      "rounded-lg px-4 py-1 text-sm transition-colors duration-200",
                    )}
                    onClick={() =>
                      setSelectedRange(range as typeof selectedRange)
                    }
                  >
                    {range.replace(/(\d+)/, "$1 ")}
                  </button>
                ))}
              </div>
            </div>
            {graphLoading ? (
              <LoaderCircle className="mx-auto my-auto size-16 animate-spin text-green" />
            ) : graphError ? (
              <p className="text-red-500">
                Erreur lors du chargement des données du graphique.
              </p>
            ) : (
              <LineChart
                data={[
                  {
                    id: "Sentiment Score Evolution",
                    data: filteredData,
                  },
                ]}
              />
            )}
          </div>

          {/* Google News */}
          {googleNews && googleNews?.length > 0 && (
            <div className="overflow-hidden rounded-3xl border border-gray-100 bg-white p-4 shadow-lg shadow-gray-300/70">
              <h2 className="text-center text-lg font-bold">
                Dernières actualités Google News
              </h2>
              <div className="mt-2 overflow-hidden rounded-3xl">
                <Table className="w-full table-auto rounded-2xl">
                  <TableHeader>
                    <TableRow className="bg-orange text-white">
                      <TableHead className="px-4 py-3">Auteur</TableHead>
                      <TableHead className="px-4 py-3">Contenu</TableHead>
                      <TableHead className="px-4 py-3">Lien</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {googleNews.map((news, index) => (
                      <TableRow
                        key={index}
                        className={index % 2 === 0 ? "bg-orange-light" : ""}
                      >
                        <TableCell className="px-4 py-2">
                          {news.author}
                        </TableCell>
                        <TableCell className="px-4 py-2">
                          {news.content}
                        </TableCell>

                        <TableCell className="px-4 py-2">
                          <a
                            href={news.url}
                            target="_blank"
                            className="text-blue underline hover:text-orange"
                            rel="noreferrer"
                          >
                            Voir l'article
                          </a>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </div>
          )}
        </div>

        {/* Sentiment Pie Chart */}
        <div className="col-span-1 h-fit rounded-3xl border border-gray-100 bg-white p-4 shadow-lg shadow-gray-300/70">
          <h2 className="text-center text-lg font-bold">Sentiment global</h2>
          {sentimentLoading ? (
            <LoaderCircle className="mx-auto size-16 animate-spin text-blue" />
          ) : sentimentError ? (
            <p className="text-red-500">
              Erreur lors du chargement des données de sentiment global.
            </p>
          ) : sentimentPieData ? (
            <SentimentPieChart sentiment={sentimentPieData} />
          ) : (
            <p className="text-red-500">
              Aucune donnée disponible pour le sentiment.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default FocusDetail;
